.items-container{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.items-list{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 250px;
    max-height: 400px;
    overflow-y: auto;
    padding: 0px 40px;
}
.nft{
    justify-content: space-between;
    align-items: center;
    min-height: 400px;
    max-height: 400px;
}
.item{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    column-gap: 10px;
}
.item2{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    column-gap: 10px;
}
.continue-button{
    margin-top: 20px;
    justify-self:flex-end;
}
.item-top{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    column-gap: 10px;
    padding-bottom: 20px;
}
.redeem-list-msg{
    font-size: 1.3em;
    text-align: center;
}