.faq{
    width:40vw;
    padding: 100px 0px;
    z-index: 999;
}
.faq .accordion i, .faq .accordion span {
    vertical-align: middle;
}

.faq .accordion span {
    margin: 0 .5rem;
}

.faq .p-accordion p {
    line-height: 1.5;
    margin: 10px;
    font-weight: 300;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}
.p-accordion-toggle-icon{
    color: #3333ff;
}
.p-accordion-header-text{
    font-weight: 700;
}
.p-accordion .p-accordion-content{
    background: rgb(53, 53, 53);
}


@media screen and (max-width: 960px) {
    .faq{
        width:80vw;
        padding: 100px 0px;
        z-index: 999;
    }

}