
.form-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form-description{
    font-size: 1.3em;
    padding: 20px 0px;
}

.form-demo .card {
    min-width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form-demo .card form {
    margin-top: 10px;
}
.form-demo .card .field {
    /* margin-bottom: 1.5rem; */
    /* flex: 1 1 50%; */
}
.success{
    height:240px;
}
.success-title{
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #3333ff;
    padding-bottom: 20px;
}
.success-msg{
    text-align: center;
    font-size: 18px;
    color:rgb(228, 228, 228);
    padding-bottom: 50px;
}



.p-fluid{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    width:500px;
    padding-bottom: 50px;
}


.p-fluid > div:nth-child(1){
    flex: 1 0 auto;
}
.p-fluid > div:nth-child(2){
    flex: 1 0 auto;
}
.p-fluid > div:nth-child(3){
    flex: 1 1 100%;
}
.p-fluid > div:nth-child(4){
    flex: 1 1 100%;
}
.p-fluid > div:nth-child(5){
    flex: 1 0 25%;
}
.p-fluid > div:nth-child(6){
    flex: 1 0 25%;
}
.p-fluid > div:nth-child(7){
    flex: 1 0 15%;
}
.p-fluid > div:nth-child(8){
    flex: 1 0 100%;
   
}

/* header */
.p-dialog .p-dialog-header {
    border-bottom: 0 none;
    /* background: rgb(228, 228, 228) !important; */
    color: rgba(255, 255, 255, 0.87);
    padding: 1.25rem;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
/* close button  */
.pi-times:before {
    content: "\e90b";
    color: rgb(26, 26, 26);
}
/* dialog content */
.p-dialog .p-dialog-content {
    /* background: rgb(228, 228, 228) !important; */
    color: rgba(255, 255, 255, 0.87);
    padding: 0 1.25rem 1.25rem 1.25rem;
}

.p-inputtext:enabled:focus {
    box-shadow: inset 0 0 0 1px #3333ff, inset 0 0 0 1px #3333ff, inset 0 0 0 1px #3333ff, inset 0 0 0 1px #3333ff;
}

@media screen and (max-width: 960px) {
    .form-demo .card {
        width: 80%;
    }
}
@media screen and (max-width: 640px) {
    .form-demo .card {
        width: 100%;
        min-width: 0;
    }
}