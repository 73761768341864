
.mint-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* width:100vw; */
    z-index: 999;
}

.hoodie{
    /* position:absolute;
    bottom:10px;
    left:0; */
    /* margin-top: -200px; */
    width:100vw;
    height:50vh;
}
.title{
    color:var(--main-font);
    /* color:rgb(24, 24, 24); */
    position: relative;
    font-size: 6rem;
    font-weight: 700;
    text-shadow: 
        0.05em 0 0 var(--c1),
        -0.025em -0.05em  0 var(--c2),
        0.025em 0.05em 0 var(--c3);

    animation: grow-title 200ms ease-out;
    /* animation: glitch 700ms infinite; */
    z-index: 2;
    user-select:none;
    
}

.sub-title{
    font-size: 1rem;
    font-weight: 400;
    padding-bottom: 0px;
    color:var(--main-font);
    user-select:none;
    /* color:rgb(24, 24, 24); */
}

.phase{
    font-size: 1.2em;
}

.address{
    padding-bottom: 10px;
    
}

.title span{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.title span:first-child{
    animation: glitch 550ms infinite;
    clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
    transform: translate(-0.015em, -0.015em);
    opacity: 0.7;
}
.title span:nth-child(2){
    animation: glitch2 1350ms infinite;
    clip-path: polygon(10% 0, 100% 0, 100% 25%, 0 15%);
    transform: translate(0.115em, 0.115em);
    opacity: 0.7;
}
.title span:nth-child(3){
    animation: glitch2 750ms infinite;
    clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 80%);
    transform: translate(0.115em, 0.115em);
    opacity: 1;
    color:rgb(0, 46, 253);
    z-index: -3;
}
.title span:last-child{
    animation: glitch 630ms infinite;
    clip-path: polygon(0 60%, 100% 60%, 100% 100%, 0 100%);
    transform: translate(-0.02em, -0.015em);
    opacity: 0.7;
}

.p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: rgb(31, 31, 31) !important;
    color: rgba(255, 255, 255, 0.87);
    padding: 1.25rem;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.p-dialog .p-dialog-content {
    background: rgb(31, 31, 31) !important;
    color: rgba(255, 255, 255, 0.87);
    padding: 0 1.25rem 1.25rem 1.25rem;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}
.pi-times:before {
    content: "\e90b";
    color: rgb(241, 241, 241) !important;
}
.p-button:disabled.p-button-text {
    background-color: #3333ff00 !important;
    color: #070707 !important;
    border:1px solid #808080 !important;
}
.p-fluid .p-button {
    width: 50%;
    background-color: #3333ff;
}

.light{
    position: absolute;

    width: 25px;
    height: 100%;
    /* filter: blur(50px); */
    animation: lights 1000ms infinite;
}
.line{
    position: absolute;
    background-color: #fff;
    width: 100%;
    height: 25px;
    filter: blur(190px);
    margin-top:-100px;
    animation: line 8000ms infinite ease-in-out;
}
.left{
    top:0;
    left: 0;
}
.right{
    top:0;
    right: 0;
}
.cntr{
    animation: lights 3000ms infinite;
}
.p-toast .p-toast-message.p-toast-message-warn {
    background: #B71C1C;
    border: solid transparent;
    border-width: 0 0 0 0;
    color: #B71C1C;
}
.pi-times:before {

    color: #ffffff !important;
}
.pi-exclamation-triangle:before {
    content: "";
    color: #000000 !important;
}
.info-msg{
    font-size: 1.5em;
}
.timer{
    font-size: 1.4em;
}

.socials{
    position: absolute;
    top:1vh;
    right:1vh;
    z-index: 999;

}
.ico{
    width:32px;
    height:auto;
    margin: 0px 5px;
    cursor: pointer;
}

.redeem{
    display: flex;
    flex-direction: row;
    row-gap: 10px;
    justify-content: center;
    margin-top: 10px;
}
.p-dropdown {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    height: 42px;
    margin-right: 0px;
    background: none;
    border-color: rgb(128, 128, 128);
}
.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: inset 0 0 0 1px #9fa8da00, inset 0 0 0 1px #9FA8DA00, inset 0 0 0 1px #9FA8DA00, inset 0 0 0 1px #9FA8DA00;
}
.p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: rgb(128, 128, 128);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #eeeeee;
    background: rgba(159, 168, 218, 0.16);
}

.supply{
    font-size: 1.3em;
    padding: 10px 0px;
}

.balance{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.token{
    font-size: 1.1em;
    border: 1px solid #3333ff;
    border-radius: 6px;
    background-color: #3333ff;
    margin: 0px 5px;
    padding: 0.2em 0.3em;
}

.popup-msg{
    font-size: 1.2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.orders-pop{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.space{
    min-height: 100px;
}
.orders-footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}
.orders-btn{
    position: absolute;
    top:2vh;
    left:calc(1vw + 170px);
    z-index: 9999;
}
.orders-btn:hover{
    transform: scale(1.01);
}
@keyframes line{
    0%{
        transform: translateY(-50vh);
    }

    50%{
        transform: translateY(50vh);
    }
    100%{
        transform: translateY(-50vh);
    }
}
@keyframes lights {
    0%{
        background-color: var(--c2);
        filter: blur(170px);
    }
    14%{
        background-color: var(--c1);
        filter: blur(160px);
    }
    44%{
        background-color: var(--c2);
        filter: blur(180px);
    }
    100%{
        background-color: var(--c3);
        filter: blur(190px);
    }
    
}
@keyframes grow-title {
    0%{
        font-size: 0rem;

        filter: blur(3px);
    }

    100%{
        font-size: 6rem;
    }
}
@keyframes glitch {
    0%{
        opacity: 0;
        text-shadow: 
            0.05em 0 0 var(--c1),
            -0.025em -0.05em  0 var(--c2),
            0.025em 0.05em 0 var(--c3);
    }
    13%{
        opacity: 0.7;
        text-shadow: 
            0.05em 0 0 var(--c1),
            -0.025em -0.05em  0 var(--c2),
            0.025em 0.05em 0 var(--c3);
    }
    15%{
        opacity: 1;
        text-shadow: 
            -0.05em -0.025em 0 var(--c1),
            0.025em 0.025em  0 var(--c2),
            -0.05em -0.05em 0 var(--c3); 
    }
    48%{
        text-shadow: 
            -0.05em -0.025em 0 var(--c1),
            0.025em 0.025em  0 var(--c2),
            -0.05em -0.05em 0 var(--c3); 
    }
    50%{
        text-shadow: 
            0.025em 0.05em 0 var(--c1),
            0.05em 0 0 var(--c2),
            0 -0.05em 0 var(--c3); 
    }
    99%{
        text-shadow: 
            0.025em 0.05em 0 var(--c1),
            0.05em 0 0 var(--c2),
            0 -0.05em 0 var(--c3); 
    }
    100%{
        text-shadow: 
            -0.025em 0 0 var(--c1),
            -0.025em -0.025em  0 var(--c2),
            -0.025em -0.05em 0 var(--c3); 
    }
}
@keyframes glitch2 {
    0%{
        text-shadow: 
            0.05em 0 0 var(--c1),
            -0.025em -0.05em  0 var(--c2),
            0.025em 0.05em 0 var(--c3);
            opacity: 0.0;
    }
    13%{
        text-shadow: 
            0.05em 0 0 var(--c1),
            -0.025em -0.05em  0 var(--c2),
            0.025em 0.05em 0 var(--c3);
            opacity: 0.0;
    }
    15%{
        text-shadow: 
            -0.05em -0.025em 0 var(--c1),
            0.025em 0.025em  0 var(--c2),
            -0.05em -0.05em 0 var(--c3);
            opacity: 0.8; 
    }
    48%{
        text-shadow: 
            -0.05em -0.025em 0 var(--c1),
            0.025em 0.025em  0 var(--c2),
            -0.05em -0.05em 0 var(--c3); 
            opacity: 0.8; 
    }
    50%{
        text-shadow: 
            0.025em 0.05em 0 var(--c1),
            0.05em 0 0 var(--c2),
            0 -0.05em 0 var(--c3); 
            opacity: 0.2; 
    }
    99%{
        text-shadow: 
            0.025em 0.05em 0 var(--c1),
            0.05em 0 0 var(--c2),
            0 -0.05em 0 var(--c3); 
            opacity: 0.2; 
    }
    100%{
        text-shadow: 
            -0.025em 0 0 var(--c1),
            -0.025em -0.025em  0 var(--c2),
            -0.025em -0.05em 0 var(--c3); 
            opacity: 1; 
    }
}