.main-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width:100% */
}
.M{
    font-size: 0rem;
    font-weight: 700;
    position: relative;
    animation: grow-m 2000ms ;
    margin-top: -150px;
    /* color:#181818; */
    color:var(--main-font);
}

.M span{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
     /* color:#181818; */
     color:var(--main-font);

}
.M span:first-child{
    animation: glitch 550ms infinite;
    clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
    transform: translate(-0.015em, -0.015em);
    opacity: 0.7;
    
}
.M span:nth-child(2){
    animation: glitch2 1350ms infinite;
    clip-path: polygon(10% 0, 100% 0, 100% 25%, 0 15%);
    transform: translate(0.115em, 0.115em);
    opacity: 0.7;
    
}
.M span:nth-child(3){
    animation: glitch2 750ms infinite;
    clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 80%);
    transform: translate(0.115em, 0.115em);
    opacity: 1;
    color:rgb(0, 46, 253);
    z-index: -3;
    
}
.M span:last-child{
    animation: glitch 630ms infinite;
    clip-path: polygon(0 60%, 100% 60%, 100% 100%, 0 100%);
    transform: translate(-0.02em, -0.015em);
    opacity: 0.7;
    
}

.circle{
    position:fixed;
    content: '';
    border: 1px solid var(--main-font);
    /* border: 1px solid #181818; */
    border-radius: 9999px;
    /* width: 200px;
    height: 200px; */
    z-index: 1;
    animation: grow-circle 2000ms 100ms ;
    clip-path: polygon(0 60%, 100% 60%, 100% 100%, 0 100%);
    margin-top: -150px;
    opacity: 0;
}
.circle2{
    position:fixed;
    content: '';
    border: 1px solid var(--main-font);
    /* border: 1px solid #181818; */
    border-radius: 9999px;
    /* width: 100px;
    height: 100px; */
    z-index: 1;
    animation: grow-circle2 2000ms 100ms ;
    margin-top: -150px;
    opacity: 0;
}
.circle3{
    animation: grow-circle3 2000ms 200ms ;
}
.circle3{
    animation: grow-circle4 2000ms 300ms ;
}

.footnote {
    /* position: absolute;
    bottom:1vh;
    right: 0;
    margin-right: 20px;
    margin-top: -30px; */
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 98vw;
    z-index: 999;
    
}

@keyframes grow-m {
    0%{
        font-size: 0rem;
        transform: rotateZ(0deg);
        filter: blur(3px);
    }
    80%{
        font-size: 6rem;
        transform: rotateZ(360deg);
    }
    100%{
        font-size: 0rem;
        transform: rotateZ(360deg);
        
    }
}


@keyframes grow-circle {
    0%{
        width: 0px;
        height: 0px;
        transform: rotateZ(0deg);
        /* box-shadow: 0 0 10px -10px #3333ff; */
        clip-path: polygon(0 60%, 100% 60%, 100% 100%, 0 100%);
    }
    
    80%{
        width: 200px;
        height: 200px;
        transform: rotateZ(360deg);
        /* box-shadow: 0 0 20px 10px #e100ff; */
        opacity: 0.8;
        
        
    }
    100%{
        width: 0px;
        height: 0px;
        opacity: 0;
    }
}

@keyframes grow-circle2 {
    0%{
        width: 0px;
        height: 0px;
        transform: rotateZ(0deg);
        /* box-shadow: 0 0 10px -10px #3333ff; */
        clip-path: polygon(0 60%, 100% 60%, 100% 100%, 0 100%);
    }
    
    80%{
        width: 180px;
        height: 180px;
        transform: rotateZ(360deg);
        /* box-shadow: 0 0 20px 10px #e100ff; */
        opacity: 0.8;
        
    }
    100%{
        width: 0px;
        height: 0px;
        opacity: 0;
    }
}

@keyframes grow-circle3 {
    0%{
        width: 0px;
        height: 0px;
        transform: rotateZ(0deg);
        box-shadow: 0 0 10px -10px #f701ff;
        /* clip-path: polygon(0 80%, 100% 80%, 100% 100%, 0 100%); */
    }
    
    80%{
        width: 220px;
        height: 220px;
        transform: rotateZ(360deg);
        /* transform: translateY(100px); */
        box-shadow: 0 0 10px 10px #3333ff;
        opacity: 0.8;
    }
    100%{
        width: 0px;
        height: 0px;
        opacity: 0;
    }
}
@keyframes grow-circle4 {
    0%{
        width: 0px;
        height: 0px;
        transform: rotateZ(0deg);
        /* box-shadow: 0 0 10px -10px #f701ff; */
        clip-path: polygon(0 80%, 100% 80%, 100% 100%, 0 100%);
        
    }
    
    80%{
        width: 200px;
        height: 200px;
        transform: rotateZ(180deg);
        /* transform: translateY(100px); */
        /* box-shadow: 0 0 -10px 10px #3333ff; */
        clip-path: polygon(0 80%, 100% 80%, 100% 100%, 0 100%);
        opacity: 0.8;
    }
    100%{
        width: 0px;
        height: 0px;
        opacity: 0;
    }
}