.message{
    font-size: 1.5em;
    text-align: center;
    color: #dbdbdb;
    padding: 0px 50px;
}

/* container */

.p-dialog-header, .p-dialog-content, .p-dialog-footer{
    background-color: #1f1f1f !important;
}

/* steps */

.steps{
    /* padding: 0px 100px 30px 100px; */
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.step-circle{
    width:42px;
    height: 42px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23808080FF' stroke-width='4' stroke-dasharray='1%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 100px;
    padding:10px 17px;
    color:#070707;
}
.steps-line{
    width: 80px;
    height: 0;
    border: 0.5px solid #808080da;
    margin: 20px 10px 0px 10px;
    display:inline-block;
}
.circle-active{
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%233333FFFF' stroke-width='3' stroke-dasharray='1%2c 9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 100px;
    color:#3333ff;
}
.line-active{
    border: 1px solid #3333ff94;
}
/* line */
.p-steps .p-steps-item:before {
    content: " ";
    border-top: 1px solid #808080;
    width: 100%;
    top: 50%;
    left: 0;
    display: block;

}
/* close */
.pi-times:before {
    content: "\e90b";
    color: #070707;
}

/* circle not active*/
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    background-color: #F6F6F6;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23808080FF' stroke-width='3' stroke-dasharray='1%2c 9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 100px;
    color: #070707;
    font-size: 0.857rem;
    min-width: 30px;
    height: 30px;
    
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    min-width: 30px;
    z-index: 1;
    border-radius: 50%;
}

.load{
    display: flex;
    flex-direction: column;
}
.success{
    padding-top:17%;
    font-size: 1.4em;
}
.error{
    padding-top: 30px;
    font-size: 22px;
    color:red;
}
.buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
}
.price{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
}
.value{
    padding:4px 20px;
    width:127px;
    color: #cacaca;
    border-top: 1px dashed #3333ff;
    border-left:1px dashed #3333ff;
    border-right:1px dashed #3333ff;
    margin: 0px 15px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}