.admin-btn{
    position: absolute;
    top:2vh;
    left:1vw;
    z-index: 9999;
}
.admin-btn:hover{
    transform: scale(1.01);
}
.datatable-doc-demo .p-paginator .p-paginator-current {
    margin-left: auto;
}
.datatable-doc-demo .p-progressbar {
    height: 0.5rem;
    background-color: #d8dadc;
}
.datatable-doc-demo .p-progressbar .p-progressbar-value {
    background-color: #607d8b;
}
.datatable-doc-demo .p-datepicker {
    min-width: 25rem;
}
.datatable-doc-demo .p-datepicker td {
    font-weight: 400;
}
.datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
}
.datatable-doc-demo .p-datatable.p-datatable-customers .p-paginator {
    padding: 1rem;
}
.datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
    text-align: left;
}
.datatable-doc-demo .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}

.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 0.75rem 0.75rem;
    border: 2px solid #3333ff;
    border-width: 0 0 1px 0;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.87);
    background: #414ddf;
    transition: none;
    /* height: 80px; */
}
.p-datatable .p-sortable-column.p-highlight {
    background: #414ddf;
    color: rgba(255, 255, 255, 0.87);
}
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: #414ddf !important;
    color: rgba(255, 255, 255, 0.87);
}
.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: #414ddf !important;
    color: rgba(255, 255, 255, 0.87);
}

.customer-badge{
    /* background-color: #8a8a8a00;
    padding: 0.25rem 0.5rem;
    border: 1px solid #d8dadc;
    border-radius: 2px; */
}
.status-waiting{
    color: coral;
}
.status-sent{
    color: rgb(88, 148, 39);
}

.search{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.dialog-row{
    
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: space-between;
    align-items: center;
    
    padding: 0px 10px;
    /* background-color: #3d3c3c;
    box-shadow: inset -2px -2px 0px 0px #181818; */
}
.end{
    justify-content: flex-end;
    padding:0px;
    column-gap:0px;
}
.separator{
    width:2px;
    height: 50px;
    border:0px solid #3333ff;
    margin: 0px 20px;
}