@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@400;700&display=swap');  

/* :root{
    --c1: rgba(42, 193, 231, 0.75);
    --c2: rgba(41, 187, 77, 0.75);
    --c3: rgba(6, 1, 255, 0.75);
} */
:root{
    --c1: rgba(0, 162, 255, 0.75);
    --c2: rgba(62, 253, 4, 0.75);
    --c3: rgba(255, 2, 120, 0.75);

    /* dark */
    --main-bg: rgb(5, 5, 5);
    --main-font: rgb(240, 240, 240);
    /* light */
    /* --main-bg: rgb(240, 240, 240);
    --main-font: rgb(5, 5, 5); */
}
*{
    font-family: 'Antonio', sans-serif;
   
    /* font-family: 'Open Sans', sans-serif; */
    color:var(--main-font);
    /* color:rgb(15, 15, 15); */
}
body{
  /* background-color: rgb(228, 228, 228); */
  overflow-x: hidden;
}
.App {
  text-align: center;
  /* background-color: rgb(228, 228, 228); */
  background-color: var(--main-bg);
  min-height: 100vh;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  /* width:100%; */
}

/*width*/
::-webkit-scrollbar {
  width: 11px;
  background:rgba(0, 0, 0, 0);
  z-index: -20;
  
}

/*Track*/
::-webkit-scrollbar-track {
  width:9px;
  background: #060b08;

  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
}

/*Handle*/
::-webkit-scrollbar-thumb {
  background: #3333ff;
  border-radius:3px;
  background-clip: padding-box;

  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  
}

/*Handle on hover*/
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;

}

