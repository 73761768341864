.info-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    /* width:100vw; */
}

.svg{
    position: absolute;
    height: 3000px;
    width:90vw;
    
}
.info-container .section{
    width: 70vw;
    z-index: 999;
    
}

.section .title{
    font-size: 5em;
}

.section .text{
    font-size: 1.4em;
    padding-bottom: 50px;
}

.section .paragraph{
    padding:10px 0px
}


.section.lleft{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
}
.section.rright{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
}

.info-container.gm{
    background: rgb(5,5,5);
    background: linear-gradient(0deg, rgba(5,5,5,1) 0%, rgba(58, 86, 214, 0.199) 51%, rgba(5,5,5,1) 100%);
    width:100vw;
    overflow: hidden;
    height: 50vh;
}

.titlex{
    font-size: 4em;
}

.linex{
    height: 2px;
    width: 180px;
    background-color: #3333ff;
    margin: 10px 0px;
}
.title.sm{

    font-size: 4rem;
    font-weight: 700;

}

.titlex{
    font-size: 5.5em;
    font-weight: 700;
}

@media screen and (max-width: 960px) {
    .info-container.gm{
        background: rgb(5,5,5);
        background: linear-gradient(0deg, rgba(5,5,5,1) 0%, rgba(58, 86, 214, 0.199) 51%, rgba(5,5,5,1) 100%);
        width:100vw;
        overflow: hidden;
        height: 100vh;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .titlex{
        font-size:4.5em;
        font-weight: 700;
    }
    .section .text{
        font-size: 1.1em;
        padding-bottom: 50px;
    }

}